import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Say Hi 👋 on `}<a parentName="p" {...{
          "href": "https://ca.linkedin.com/in/rhythm-vashist"
        }}>{`LinkedIn`}</a>{`.`}</p>
      <blockquote parentName="blockquote">
        <p parentName="blockquote"><strong parentName="p">{` Have some time, take at look at some on my work 👉 `}<a parentName="strong" {...{
              "href": "https://github.com/rhythmvashist"
            }}>{`Github`}</a>{` 💻  `}</strong>{` or Reach out to me on my `}<strong parentName="p">{`📧  `}<a parentName="strong" {...{
              "href": "mailto:rhythmvashist99@gmail.com"
            }}>{`Email`}</a>{`.`}</strong></p>
      </blockquote>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      