import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <h1 parentName="blockquote">{`Hi, I'm Rhythm 😄`}</h1>
    </blockquote>
    <blockquote>
      <h3 parentName="blockquote">{`I'm a Web developer based in 📍 Vancouver, Canada.`}</h3>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      