import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Realtor Site" link="https://github.com/rhythmvashist/Realtor-Website-Django-Project/blob/master/README.md" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
A responsive web application built using Django for buyer and seller to put post on their property and contact each other.
    </ProjectCard>
    <ProjectCard title="MovieWatch" link="https://github.com/rhythmvashist/MovieWatch" bg="linear-gradient(to right, #a8dadc 0%, #457b9d 100%)" mdxType="ProjectCard">
A React Web application that enable users to explore new movies & TV shows and keep track of movies they have already watched or Movies or TV shows they were deciding to buy by adding them to watchlist or pending list.
    </ProjectCard>
    <ProjectCard title="Pacman" link="https://github.com/rhythmvashist/Pacman" bg="linear-gradient(to top, #38a3a5 0%, #eeef20 100%)" mdxType="ProjectCard">
A Pacman game built using Object Oriented paradigm that lets the user control the Pacman via the GUI. Wrote unit tests for all components of the game using Junit.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      