import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I am Full Stack developer & I loves to build cool things 🚀 that solve problems. `}</p>
    </blockquote>
    <p>{`I have a passion for Web development and love to create for web and mobile devices. I'm a developer, so I know how to create your website to run across devices using the latest technologies available. `}<strong parentName="p">{` My Tech. Stack include ⚛️ React, Node.js, Django. SQL, NoSQL `}</strong></p>
    <p>{`If you have a project that you want to get started, think you need my help with something or just fancy saying hey, `}<a parentName="p" {...{
        "href": "mailto:rhythmvashist99@gmail.com"
      }}>{`then get in touch`}</a>{` 🔽.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you want to know more about me have a look at my `}<a parentName="p" {...{
          "href": "https://drive.google.com/file/d/1UAHs-5k1_2jGweENLQKo0iIz489VFNoZ/view?usp=sharing"
        }}>{`Resume`}</a>{`.    `}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      